<template>
  <div
    ref="main"
    v-motion="{
      initial: {
        y: 100,
        opacity: 0.2,
      },
      visibleOnce: {
        y: 0,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    }"
    class="relative w-full"
  >
    <UContainer
      class="number-one-section flex grid-cols-12 items-center md:grid md:items-start"
    >
      <!--  LEFT -->
      <ul
        class="col-span-4 hidden h-full flex-col items-end justify-evenly gap-12 md:flex lg:col-span-4"
      >
        <li
          v-for="(feature, index) in leftFeatures"
          :key="index"
          class="flex flex-row-reverse items-center gap-2"
          :class="[1, 2].includes(index) && 'mr-10'"
        >
          <UIcon
            :name="feature.icon"
            class="text-primary size-8"
          />
          <span class="t-subtitle text-text-2">
            {{ feature.title }}
          </span>
        </li>
      </ul>
      <!--  CENTER -->
      <SvgNumberOneBig
        :class="$isSafari ? 'mx-auto w-fit' : 'w-auto'"
        class="col-span-4 my-auto max-w-80 scale-125 xs:scale-100 md:max-w-none"
      />

      <!--  RIGHT+ -->
      <ul
        class="col-span-4 hidden h-full flex-col items-start justify-evenly gap-12 md:flex lg:col-span-4"
      >
        <li
          v-for="(feature, index) in rightFeatures"
          :key="index"
          class="flex items-center gap-2"
          :class="[1, 2].includes(index) && 'ml-10'"
        >
          <UIcon
            :name="feature.icon"
            class="text-primary size-8"
          />
          <span class="t-subtitle text-text-2">
            {{ feature.title }}
          </span>
        </li>
      </ul>

      <!-- MOBILE FEATURES -->

      <ul class="flex flex-col gap-4 md:hidden">
        <li
          v-for="(feature, index) in allFeatures"
          :key="index"
          class="flex items-center gap-2"
          :class="getMarginClassByIndex(index)"
        >
          <UIcon
            :name="feature.icon"
            class="text-primary size-5"
          />
          <span class="t-body whitespace-nowrap text-text-2">
            {{ feature.title }}
          </span>
        </li>
      </ul>

      <SvgSectionCloud
        fluid
        class="pointer-events-none absolute left-0 top-1/4 z-0 h-full w-4/5 translate-y-[-10%] bg-opacity-10"
      />
    </UContainer>
  </div>
</template>
<script setup lang="ts">
import SvgNumberOneBig from '@/components/svg/NumberOneBig.vue'

const { t } = useI18n()

const main = ref<HTMLElement | null>(null)
const { $isSafari } = useNuxtApp()

/* useOnScrollAnimation({
  container: main,
  selector: '.number-one-section',
  animationInitial: { opacity: 0, y: 100 },
  scrollSelector: '.number-one-section',
  animation: {
    opacity: 1,
    y: 0,
    duration: 0.5,
    delay: 0.5,
  },
}) */

const leftFeatures = [
  {
    icon: 'i-j-projects-active',
    title: t('c.issueManagement'),
  },
  {
    icon: 'i-j-docs-active',
    title: t('c.projectDocumentation'),
  },
  {
    icon: 'i-j-roadmap-active',
    title: t('c.roadmapPlanning'),
  },
  {
    icon: 'i-j-test-modul-active',
    title: t('c.qualityTesting'),
  },
]
const rightFeatures = [
  {
    icon: 'i-j-timesheet-active',
    title: t('c.timeManagement'),
  },
  {
    icon: 'i-j-dashboard-active',
    title: t('c.dashboard'),
  },
  {
    icon: 'i-j-audit-log-active',
    title: t('c.auditLog'),
  },
  {
    icon: 'i-j-reports-active',
    title: t('c.reports'),
  },
]

const allFeatures = [...leftFeatures, ...rightFeatures]

function getMarginClassByIndex(index: number) {
  switch (index) {
    case 0:
      return 'ml-0'
    case 1:
      return 'ml-6'
    case 2:
      return 'ml-10'
    case 3:
    case 4:
      return 'ml-12'
    case 5:
      return 'ml-10'
    case 6:
      return 'ml-6'
    case 7:
      return 'ml-0'

    default:
      return ''
  }
}
</script>
<style scoped lang="scss">
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:deep(.animated-path-number-one:not(.safari)) {
  transform-origin: center;

  @screen sm {
    animation: rotate 16s linear infinite;
  }
}
</style>
