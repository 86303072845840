<template>
  <NuxtImg
    :loading="loading"
    :src="light"
    class="dark:hidden"
    v-bind="$attrs"
    :sizes="sizes"
    @load="() => emit('load')"
  />
  <NuxtImg
    :loading="loading"
    :src="dark"
    class="hidden dark:block"
    v-bind="$attrs"
    :sizes="sizes"
    @load="() => emit('load')"
  />
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits(['load'])

defineProps({
  dark: {
    type: String,
    required: true,
  },
  light: {
    type: String,
    required: true,
  },
  loading: {
    type: String,
    default: 'eager',
  },
  sizes: {
    type: String,
    default: undefined,
  },
  /*   quality: {
    type: String,
    default: '80',
  },
  format: {
    type: String,
    default: 'webp',
  }, */
})
</script>
