<template>
  <Transition
    appear
    name="fade"
  >
    <div
      :style="{
        '--cell': `${width / cols}px`,
        '--rows': rows - 1,
      }"
    >
      <div
        ref="el"
        class="absolute inset-0 grid auto-rows-[--cell] justify-center -space-y-px"
      >
        <!-- TRANSITION ELEMENT -->
        <div
          id="animated-box-wrapper"
          class="absolute inset-0 hidden flex-1 auto-cols-[--cell] grid-flow-col -space-x-px"
        >
          <div
            id="animated-box"
            class="animated-box relative"
          />
        </div>
        <div
          id="animated-box-2-wrapper"
          class="absolute inset-0 hidden flex-1 auto-cols-[--cell] grid-flow-col -space-x-px"
        >
          <div
            id="animated-box-2"
            class="animated-box relative"
          />
        </div>
        <div
          v-for="(row, rowIndex) in grid"
          :key="rowIndex"
          class="grid flex-1 auto-cols-[--cell] grid-flow-col -space-x-px"
        >
          <div
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            class="border-primary-200/80 relative border transition-all duration-300"
            :class="[
              appConfig.ui.primary === 'slate'
                ? 'dark:border-primary-800/40 transition-opacity duration-300'
                : 'dark:border-primary-900/10',
              appConfig.ui.primary === 'pinot'
                ? 'border-primary-200/80'
                : 'border-primary-200/20',
            ]"
          />
        </div>

        <div
          v-if="withGradient"
          class="pointer-events-none absolute inset-x-0 top-[calc((var(--cell)*var(--rows))+1px)] hidden h-[calc(var(--cell)*2)] bg-gradient-to-t from-[rgb(var(--bg-color))] md:block"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { useElementSize } from '@vueuse/core'

defineProps<{
  withGradient?: boolean
}>()

defineExpose({
  resize: calcGrid,
})

const { $gsap: gsap } = useNuxtApp()
const el = ref(null)
const grid = ref<any[]>([])
const rows = ref(0)
const cols = ref(0)

// render all grid rows but the last one

const { width, height } = useElementSize(el)

const appConfig = useAppConfig()

function createGrid() {
  grid.value = []

  for (let i = 0; i <= rows.value; i++) {
    grid.value.push(new Array(cols.value).fill(null))
  }
}

function calcGrid(startAnimations?: boolean) {
  console.log('calcGrid')
  const itemSize = width.value < 768 ? 60 : 160
  const rowsMargin = width.value < 768 ? 1 : 1
  const base = Math.ceil(width.value / itemSize)
  const cell = width.value / base

  rows.value = Math.ceil(height.value / cell) - rowsMargin

  cols.value = Math.ceil(width.value / cell)

  createGrid()
  if (startAnimations) {
    startBoxAnimations()
  }
}

watch(width, () => calcGrid())
let animatedBoxPosition = 0
let animatedBox2Position = 0
const animationDuration = 8

onMounted(() => {
  setTimeout(() => calcGrid(true), 50)
})

const mainTweens = ref<gsap.core.Tween[]>([])
function startBoxAnimations() {
  mainTweens.value.forEach((tween) => tween.kill())

  animatedBoxPosition = Math.floor(Math.random() * (rows.value - 1))
  animatedBox2Position = Math.floor(Math.random() * (rows.value - 1))

  while (animatedBox2Position === animatedBoxPosition) {
    animatedBox2Position = Math.floor(Math.random() * rows.value)
    if (animatedBox2Position !== animatedBoxPosition) {
      break
    }
  }

  // set boxes x position
  const x1Position = (animatedBoxPosition * width.value) / cols.value
  const x2Position = (animatedBox2Position * width.value) / cols.value

  const setTween = gsap.set('#animated-box', {
    left: x1Position,
  })
  const set2Tween = gsap.set('#animated-box-2', {
    left: x2Position,
  })

  // show boxes
  const set3Tween = gsap.set('#animated-box-wrapper', {
    display: 'grid',
    y: `-20%`,
    opacity: 100,
  })
  const set4Tween = gsap.set('#animated-box-2-wrapper', {
    display: 'grid',
    y: `-20%`,
    opacity: 100,
  })

  const tween = gsap.to('#animated-box-wrapper', {
    duration: animationDuration,
    y: `90%`,
    opacity: 0,
    onComplete: () => {
      animateBox()
    },
  })
  const tween2 = gsap.to('#animated-box-2-wrapper', {
    duration: animationDuration,
    delay: 2,
    y: `90%`,
    opacity: 0,
    onComplete: () => {
      animateBox2()
    },
  })

  mainTweens.value.push(
    setTween,
    set2Tween,
    set3Tween,
    set4Tween,
    tween,
    tween2
  )
}

const box1Tweens = ref<gsap.core.Tween[]>([])
function animateBox() {
  // kill previous tweens
  box1Tweens.value.forEach((tween) => tween.kill())

  animatedBoxPosition = Math.floor(Math.random() * (rows.value - 1))
  while (animatedBox2Position === animatedBoxPosition) {
    animatedBoxPosition = Math.floor(Math.random() * rows.value)
    if (animatedBox2Position !== animatedBoxPosition) {
      break
    }
  }

  const x1Position = (animatedBoxPosition * width.value) / cols.value
  const setTween = gsap.set('#animated-box', {
    left: x1Position,
  })
  const set2Tween = gsap.set('#animated-box-wrapper', {
    y: `-20%`,
    opacity: 100,
  })
  const tween = gsap.to('#animated-box-wrapper', {
    duration: animationDuration,
    y: `90%`,
    opacity: 0,
    onComplete: () => {
      animateBox()
    },
  })

  box1Tweens.value.push(setTween, set2Tween, tween)
}

const box2Tweens = ref<gsap.core.Tween[]>([])
function animateBox2() {
  // kill previous tweens
  box2Tweens.value.forEach((tween) => tween.kill())

  animatedBox2Position = Math.floor(Math.random() * (rows.value - 1))
  while (animatedBox2Position === animatedBoxPosition) {
    animatedBox2Position = Math.floor(Math.random() * rows.value)
    if (animatedBox2Position !== animatedBoxPosition) {
      break
    }
  }
  const x2Position = (animatedBox2Position * width.value) / cols.value
  const setTween = gsap.set('#animated-box-2', {
    left: x2Position,
  })
  const set2Tween = gsap.set('#animated-box-2-wrapper', {
    y: `-20%`,
    opacity: 100,
  })
  const tween = gsap.to('#animated-box-2-wrapper', {
    duration: animationDuration,
    y: `90%`,
    opacity: 0,
    onComplete: () => {
      animateBox2()
    },
  })

  box2Tweens.value.push(setTween, set2Tween, tween)
}

onUnmounted(() => {
  mainTweens.value.forEach((tween) => tween.kill())
  box2Tweens.value.forEach((tween) => tween.kill())
  box1Tweens.value.forEach((tween) => tween.kill())
  mainTweens.value = []
  box2Tweens.value = []
  box1Tweens.value = []
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.animated-box {
  border: none; /* Ensure no border on other sides */
  border-right: 1px solid; /* Apply border on the right side only */
  border-image: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--color-primary-400) / 50%)
    )
    1 100%; /* Apply gradient to the right border */
  height: 100px;
}
</style>
