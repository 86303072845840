<template>
  <UContainer
    v-motion="{
      initial: {
        y: 100,
        opacity: 0.2,
      },
      visibleOnce: {
        y: 0,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      },
    }"
    class="flex flex-col items-center gap-6"
  >
    <h2
      class="t-heading-3 lg:t-heading-1 text-center font-semibold text-text-1"
    >
      {{ title }}
    </h2>
    <p class="t-body lg:t-subtitle text-center text-text-2">
      {{ description }}
    </p>

    <UButton
      size="lg"
      :label="t('c.getStarted')"
      :to="appConfig.getStartedUrl"
    />
  </UContainer>
</template>
<script setup lang="ts">
defineProps<{
  title: string
  description: string
}>()

const { t } = useI18n()
const appConfig = useAppConfig()
</script>
